import Vue from 'vue'
import VueRouter from 'vue-router'

import store from '@/store'
import Auth from '@/api/services/Auth.js'

Vue.use(VueRouter)

const getCookie = (name) => {
  const value = `; ${document.cookie}`
  const parts = value.split(`; ${name}=`)
  if (parts.length === 2) return parts.pop().split(';').shift()
  return null
}

const hasPermission = (permission) => {
  for (let i = 0; i < permission.length; i++) {
    var obj = permission[i].split('.')
    for (let x = 0; x < store.state.portal.profile.user_permissions.length; x++) {
      if (obj[1] === store.state.portal.profile.user_permissions[x].codename && obj[0] === store.state.portal.profile.user_permissions[x].content_type.app_label) {
        return false
      }
    }
    for (let x = 0; x < store.state.portal.profile.groups.length; x++) {
      for (let z = 0; z < store.state.portal.profile.groups[x].permissions.length; z++) {
        if (obj[1] === store.state.portal.profile.groups[x].permissions[z].codename && obj[0] === store.state.portal.profile.groups[x].permissions[z].content_type.app_label) {
          return false
        }
      }
    }
  }
  return true
}

const isLogged = async (to, from, next) => {
  console.log('isLogged')
  let accessToken = await getCookie("access_token")
  if (accessToken) {
    console.log(accessToken)
    await store.commit("portal/setLogin", {
      access: accessToken,
      // refresh: refreshToken,
    });
    await Auth.me().then(response => {
      store.commit('portal/setProfile', response.data.results[0])
    })
    const data = await store.state.portal
    if (data.login.access && Object.keys(data.profile).length !== 0) {
      if (to.path === '/' && !hasPermission(['finance.is_damia_recruiter', 'finance.is_meta_recruiter'])) {
        next('/reports/finance')
      } else {
        if (!getCookie('my-refresh')) {
          window.location.href = window.location.origin
          const dataExpiracao = new Date();
          dataExpiracao.setTime(dataExpiracao.getTime() + (60 * 60 * 1000)); // 1 hora = 60 minutos * 60 segundos * 1000 milissegundos
          const expires = "expires=" + dataExpiracao.toUTCString();
          document.cookie = "my-refresh=1;" + expires + ";path=/";
        }
        next()
      }
      return
    }
  }
  window.location.href = window.location.origin + '/auth/login/'
}

const authRedirect = (to, from, next) => {
  store.commit('portal/setLogin', {
    access: to.params.token,
    refresh: to.params.refresh
  })
  Auth.me().then(response => {
    store.commit('portal/setProfile', response.data.results[0])
    if (to.query.url) {
      next(to.query.url)
    } else {
      next('/')
    }
  }).catch(error => {
    Auth.logout()
    next('/')
  })
}

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  scrollBehavior() {
    return { x: 0, y: 0 }
  },
  routes: [
    {
      path: '/',
      name: 'home',
      component: () => import(/* webpackChunkName: "dashboard" */'@/views/dashboard/Dashboard.vue'),
      beforeEnter: isLogged,
      meta: {
        pageTitle: 'Dashboard',
        breadcrumb: [
          {
            text: 'Dashboard',
            active: true,
          },
        ],
      },
    },
    {
      path: '/sso/:token/:refresh',
      name: 'sso',
      beforeEnter: authRedirect,
      props: true
    },
    {
      path: '/reports/finance',
      name: 'report-finance',
      component: () => import(/* webpackChunkName: "report-finance" */'@/views/reports/Finance.vue'),
      beforeEnter: isLogged,
      meta: {
        pageTitle: 'Finance',
        breadcrumb: [
          {
            text: 'Reports',
            active: false
          },
          {
            text: 'Finance',
            active: true,
          }
        ],
      },
    },
    {
      path: '/reports/cockpit',
      name: 'report-cockpit',
      component: () => import(/* webpackChunkName: "report-cockpit" */'@/views/reports/Chat.vue'),
      beforeEnter: isLogged,
      meta: {
        pageTitle: 'Finance',
        breadcrumb: [
          {
            text: 'Reports',
            active: false
          },
          {
            text: 'Cockpit AI',
            active: true,
          }
        ],
      },
    },
    // {
    //   path: '/login',
    //   name: 'login',
    //   component: () => import(/* webpackChunkName: "auth-login" */ '../views/authentication/Login.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/forgot-password',
    //   name: 'forgot-password',
    //   component: () => import(/* webpackChunkName: "auth-forgot-password" */ '@/views/authentication/ForgotPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    // {
    //   path: '/reset-password/:token',
    //   name: 'reset-password',
    //   component: () => import(/* webpackChunkName: "auth-reset-password" */ '@/views/authentication/ResetPassword.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    //   props: true
    // },
    // {
    //   path: '/register',
    //   name: 'register',
    //   component: () => import(/* webpackChunkName: "auth-register" */ '@/views/authentication/Register.vue'),
    //   meta: {
    //     layout: 'full',
    //   },
    // },
    {
      path: '/not-authorized',
      name: 'not-authorized',
      component: () => import(/* webpackChunkName: "auth-not-authorized" */ '@/views/authentication/NotAuthorized.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '/error-404',
      name: 'error-404',
      component: () => import('@/views/error/Error404.vue'),
      meta: {
        layout: 'full',
      },
    },
    {
      path: '*',
      redirect: 'error-404',
    },
  ],
})

// ? For splash screen
// Remove afterEach hook if you are not using splash screen
router.afterEach(() => {
  // Remove initial loading
  const appLoading = document.getElementById('loading-bg')
  if (appLoading) {
    appLoading.style.display = 'none'
  }
})

export default router
