import { $themeConfig } from '@themeConfig'

export default {
  namespaced: true,
  state: {
    login: {},
    profile: {},
    financeColumns: []
  },
  getters: {},
  mutations: {
    setLogin (state, data) {
      state.login = data
    },
    setProfile (state, data) {
      state.profile = data
    },
    setFinanceColumns (state, data) {
      state.financeColumns = data
    },
    logout (state) {
      Object.assign(state, {
        login: {},
        profile: {},
        financeColumns: []
      })
    }
  },
  actions: {},
}
