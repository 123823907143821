import Vue from 'vue'
import { ToastPlugin, ModalPlugin } from 'bootstrap-vue'
import VueCompositionAPI from '@vue/composition-api'

import VueLoading from 'vuejs-loading-plugin'
import Vueditor from 'vueditor'

import PrimeVue from 'primevue/config'

import router from './router'
import store from './store'
import App from './App.vue'
import moment from 'moment'

import { library } from '@fortawesome/fontawesome-svg-core'
import { faSort } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import VueVirtualScroller from 'vue-virtual-scroller'

import * as Sentry from "@sentry/vue";

// Global Components
import './global-components'
import 'vueditor/dist/style/vueditor.min.css'
// 3rd party plugins
import '@/libs/portal-vue'
import '@/libs/toastification'
import VueAwesomeSwiper from 'vue-awesome-swiper'


Sentry.init({
  App,
  dsn: "https://74c5ac5d57bac4ae5845687080b79c0b@o4508001902329856.ingest.de.sentry.io/4508047703081040",
  integrations: [
    Sentry.browserTracingIntegration({ router }),
    Sentry.replayIntegration(),
    Sentry.browserProfilingIntegration(),
],
  // Tracing
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: [/^https:\/\/dev\.finance\.damiagroup\.pt/, /^https:\/\/finance\.damiagroup\.pt/],
  // Session Replay
  replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
  replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

library.add(faSort)
Vue.component('font-awesome-icon', FontAwesomeIcon)

Vue.use(VueVirtualScroller)
// BSV Plugin Registration
Vue.use(ToastPlugin)
Vue.use(ModalPlugin)

Vue.use(VueLoading)

Vue.use(PrimeVue)

let config = {
  toolbar: [
    'removeFormat', 'undo', '|', 'elements',
  ],
  uploadUrl: '',
}
Vue.use(Vueditor, config)

// Composition API
Vue.use(VueCompositionAPI)

Vue.filter('currency', function (value) {
  if (!value) {
    return ''
  }
  return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,") + ' €'
})

Vue.filter('currencyFormat', function (value) {
  if (!value) {
    return '0.00'
  }
  return parseFloat(value).toFixed(2).replace(/(\d)(?=(\d{3})+(?:\.\d+)?$)/g, "$1,")
})

Vue.filter('formatDate', function (value) {
  if (value) {
    return moment(String(value)).format('MM/DD/YYYY hh:mm')
  }
})

Vue.filter('stageFilter', function (value) {
  if (value === 0) {
    return 'Today'
  }
  return value
})

Vue.filter('stageFilterInStage', function (value) {
  if (value.formattedRow[value.column.field] === 0 && value.formattedRow['sent_date'] === moment(String(new Date())).format('DD-MM-YYYY')) {
    return 'Today'
  } else {
    return value.formattedRow[value.column.field]
  }
})

Vue.filter('stageFilterInProcess', function (value) {
  if (value.formattedRow[value.column.field] === 0 && value.formattedRow['cp_date'] === moment(String(new Date())).format('DD-MM-YYYY')) {
    return 'Today'
  } else {
    return value.formattedRow[value.column.field]
  }
})

Vue.filter('toCurrency', function (value) {
  if (typeof value !== "number") {
    value = 0.00;
  }
  var formatter = new Intl.NumberFormat('de-DE', {
    style: 'currency',
    currency: 'EUR'
  });
  return formatter.format(value);
})

// import core styles
require('@core/scss/core.scss')

// import assets styles
require('@/assets/scss/style.scss')

Vue.config.productionTip = false

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app')
